<template>
  <div :class="collapseClass">
    <!-- collapse trigger -->
    <button
      @click="isExpanded = !isExpanded"
      class="w-100 rounded-0 p-0 text-dark-3 border-0 text-decoration-none"
      :aria-expanded="isExpanded"
      :aria-controls="id"
      :id="`cost-collapse-${id}`"
      :class="collapseClass"
    >
      <div class="d-flex flex-row py-space-xs px-space-sm align-items-center">
        <!-- icon block -->
        <div class="me-space-xs d-flex align-items-center my-space-xxxs">
          <font-awesome-icon :icon="currentIcon"></font-awesome-icon>
        </div>
        <!-- title and total amount -->
        <div
          class="d-flex flex-row collapse-head-content align-items-center w-100 justify-content-between"
        >
          <h3 class="mb-0 text-capitalize fw-bold" :class="collapseTitleClass">
            {{ title }}
          </h3>
          <p
            v-if="!isExpanded"
            class="mb-0 fw-bold"
            :class="collapseTitleClass"
          >
            {{ amount }}
          </p>
        </div>
      </div>
    </button>

    <!-- collapse breakdown -->
    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div
        :id="id"
        class="collapse px-space-sm py-space-xs collapse-body fs-small border border-2"
        ref="collapse"
        :class="collapseBodyClass"
        v-show="isExpanded"
        role="region"
      >
        <div
          class="d-flex flex-row collapse-body-content border-bottom border-light-2"
          v-for="(item, index) in items"
          :key="index"
        >
          <p class="my-space-xxs">{{ item.title }}</p>
          <p class="my-space-xxs">{{ item.amount }}</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { VariantOption } from "~/types/types";

interface CostItem {
  title: string;
  amount: string;
}

interface Props {
  id: string;
  title: string;
  amount: string;
  bgVariant?: VariantOption;
  borderVariant?: VariantOption;
  bodyVariant?: VariantOption;
  items: CostItem[];
  collapseTitleSize?: string;
}

const props = withDefaults(defineProps<Props>(), {
  bgVariant: "light-4",
  borderVariant: "light-2",
  bodyVariant: "white",
  collapseTitleSize: "small",
});

const emits = defineEmits<{
  /**
   *  emitted when the collapse is shown.
   */
  (e: "collapseShown"): void;
  /**
   *  emitted when the collapse is hidden.
   */
  (e: "collapseHidden"): void;
}>();

const isExpanded = ref(false);
const initialLoad = ref(false);

const currentIcon = computed(() => {
  return isExpanded.value ? "circle-minus" : "circle-plus";
});

const collapseClass = computed(() => {
  return `bg-${props.bgVariant} `;
});

const collapseBodyClass = computed(() => {
  return `bg-${props.bodyVariant} border-${props.borderVariant}`;
});

const collapseTitleClass = computed(() => {
  return `h3-${props.collapseTitleSize}`;
});

// collapse transition inspired by https://github.com/danielmalmros vue accordion
const start = (el: HTMLElement | Element) => {
  if (el instanceof HTMLElement) {
    if (isExpanded.value) {
      el.style.height = "0px";
      el.style.height = el.scrollHeight + "px";
    } else if (initialLoad.value) {
      el.style.height = el.scrollHeight + "px";
      setTimeout(() => {
        el.style.height = "0px";
      }, 10);
    }
    initialLoad.value = true;
  }
};

const end = (el: HTMLElement | Element) => {
  if (el instanceof HTMLElement) {
    el.style.height = "";
    isExpanded.value ? emits("collapseShown") : emits("collapseHidden");
  }
};
</script>

<style lang="scss" scoped>
.collapse-body {
  div:last-child {
    font-weight: bold;
  }
}
.collapse-body-content {
  p:first-child {
    flex-grow: 1;
  }
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.2s ease;
  overflow: hidden;
}
</style>
